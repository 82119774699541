import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
// import Social from "./Social";

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      configJson {
        footer {
          copyright_text
          copyright_link
        }
      }
      allFooterMenuJson {
        edges {
          node {
            weight
            url
            name
          }
        }
      }
    }
  `);

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-inner">
              {/* <Social /> */}
              <span>&nbsp;</span>

              <ul>
                {data.allFooterMenuJson.edges.map(({ node }) => (
                  <li key={node.name}>
                    <Link to={node.url}>{node.name}</Link>
                  </li>
                ))}
              </ul>
              {/* <div className="copyright">
                <span>{data.configJson.footer.copyright_text}</span>
                {data.configJson.footer.copyright_link && <a href={data.configJson.footer.copyright_link}>{data.configJson.footer.copyright_link}</a>}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
