import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import favicon from "../../static/favicon-32x32.svg";

const SEO = (props) => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const title = props.title || data.site.siteMetadata.title;
      return (
        <Helmet
          htmlAttributes={{ lang: "de" }}
          title={title}
          titleTemplate={props.title ? `%s` : `%s - ${data.site.siteMetadata.title}`}
          link={[{ rel: "shortcut icon", type: "image/svg", href: `${favicon}` }]}
        >
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.juliane-bartelt.de/" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:description" content={data.site.siteMetadata.description} />
          <meta property="og:image" content="https://www.juliane-bartelt.de/images/preview-image.jpg"></meta>
          <meta property="og:locale" content="de_DE"></meta>
        </Helmet>
      );
    }}
  />
);

SEO.defaultProps = {
  lang: "de",
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
